var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{staticClass:"defaul-app-bar",attrs:{"app":"","flat":""}},[(
        !_vm.navigationDrawer &&
        _vm.activeTab !== _vm.AppBarTab.USERFORM &&
        _vm.activeTab !== _vm.AppBarTab.ERROR
      )?_c('v-text-field',{staticClass:"defaul-app-bar-search",attrs:{"dense":"","outlined":"","flat":"","hide-details":"","clearable":"","label":"Cerca"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('cosmopol-icon',{staticClass:"mr-2",attrs:{"name":"search","fill":"var(--v-primary-lighten3)"}})]},proxy:true}],null,false,1836320015),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-spacer'),(_vm.usersManagementAcess)?_c('cosmopol-btn',{staticStyle:{"margin-right":"24px","cursor":"pointer"},attrs:{"btnType":_vm.BtnType.CUSTOM,"customBtn":_vm.usersBtn},on:{"on-click":_vm.accountClick}}):_vm._e(),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"defaul-app-bar-avatar",attrs:{"size":"40"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"c-h6 c-txt c-txt-primary-l1 text-uppercase"},[_vm._v(" "+_vm._s(_vm.usernameAvatar)+" ")])])]}}])},[_c('v-card',{attrs:{"flat":"","color":"white","min-width":"270"}},[_c('v-card-text',[(_vm.user)?_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"pt-0 c-h6 text-capitalize",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.user.userDescription)+" ")]),_c('v-col',{staticClass:"py-0 c-label-regular c-txt-primary",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])],1):_vm._e(),_c('v-divider',{staticClass:"my-4"}),_c('v-row',{staticClass:"logout-btn-row",attrs:{"dense":"","align":"center"},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"var(--v-primary-base)"}},[_vm._v(" mdi-logout ")])],1),_c('v-col',{staticClass:"c-body-regular c-txt-primary"},[_vm._v(" Logout")])],1)],1)],1)],1)],1),_c('v-main',{staticClass:"defaul-main"},[(_vm.showToolbar)?_c('v-toolbar',{staticClass:"defaul-toolbar c-body-bold c-txt-primary",attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.dailyJobsTabs),function(tab){return _c('v-col',{key:tab.id,staticClass:"c-col-align-center defaul-toolbar-tab defaul-toolbar-col",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"cosmopol-default-tab-btn",class:("tab-hover-" + (tab.color) + " " + (_vm.activeTab === tab.id && 'tab-active-' + tab.color)),attrs:{"text":"","tile":"","plain":"","ripple":{ class: ((tab.color) + "--text") },"to":tab.to}},[_c('v-list-item',{staticClass:"px-3",attrs:{"ripple":false}},[_c('v-list-item-icon',{staticClass:"ma-0"},[_c('cosmopol-icon',{staticStyle:{"margin":"13px 8px 0 0"},attrs:{"name":tab.icon,"fill":_vm.activeTab === tab.id
                      ? 'var(--v-' + tab.color + '-base)'
                      : 'var(--v-primary-lighten2)'}})],1),_c('v-list-item-content',{staticClass:"pa-0 c-txt-primary"},[_c('b',[_vm._v(_vm._s(tab.name))])])],1)],1)],1)}),_c('v-spacer'),(
            _vm.activeTab != null &&
            _vm.activeTab != undefined &&
            _vm.activeTab != _vm.AppBarTab.EXAMPLES
          )?_c('v-col',{staticClass:"c-col-align-center defaul-toolbar-col px-5",attrs:{"cols":"auto"}},[_c('v-menu',{key:_vm.activeTab,attrs:{"close-on-click":"","z-index":"101","content-class":"cosmopol-help-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('cosmopol-icon',{attrs:{"name":"help-circle"}})],1)]}}],null,false,338757594),model:{value:(_vm.helpMenu),callback:function ($$v) {_vm.helpMenu=$$v},expression:"helpMenu"}},[_c(_vm.activeTab === _vm.AppBarTab.EMERGENCIES
                  ? 'emergencies-help-content'
                  : _vm.activeTab === _vm.AppBarTab.ANOMALIES
                  ? 'anomalies-help-content'
                  : _vm.activeTab === _vm.AppBarTab.REGULAR
                  ? 'regular-help-content'
                  : _vm.activeTab === _vm.AppBarTab.ARCHIVED
                  ? 'archived-help-content'
                  : 'audit-help-content',{tag:"component"})],1)],1):_vm._e(),_vm._l((_vm.otherTabs),function(tab){return _c('v-col',{key:tab.id,staticClass:"c-col-align-center defaul-toolbar-tab defaul-toolbar-col",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"cosmopol-default-tab-btn tab-hover-primary",class:("tab-hover-" + (tab.color) + " " + (_vm.activeTab === tab.id && 'tab-active-' + tab.color)),attrs:{"text":"","tile":"","plain":"","ripple":{ class: ((tab.color) + "--text") },"to":tab.to}},[_c('v-list-item',{staticClass:"px-3",attrs:{"ripple":false}},[_c('v-list-item-icon',{staticClass:"ma-0"},[_c('cosmopol-icon',{staticStyle:{"margin":"13px 8px 0 0"},attrs:{"name":tab.icon,"fill":_vm.activeTab === tab.id
                      ? 'var(--v-' + tab.color + '-base)'
                      : 'var(--v-primary-lighten2)'}})],1),_c('v-list-item-content',{staticClass:"pa-0 c-txt-primary"},[_c('b',[_vm._v(_vm._s(tab.name))])])],1)],1)],1)})],2)],1):_vm._e(),_c('v-container',{staticClass:"defaul-main-container",style:(("max-height: calc(100vh - " + (_vm.activeTab === _vm.AppBarTab.USER ||
        _vm.activeTab === _vm.AppBarTab.USERFORM ||
        _vm.activeTab === _vm.AppBarTab.ERROR
          ? 0
          : 52) + "px - 64px) !important")),attrs:{"fluid":""}},[_c('router-view')],1),_c('v-overlay',{attrs:{"value":_vm.helpMenu,"z-index":"100"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }